<template>
  <div id="impressum">
      <div class="container col-6">
<h1>Impressum</h1>

<p>
Gesellschafter:<br>
Steuerberaterin Dipl.-Wirtschaftsing.  <strong>Adelheid Schwella</strong><br>
Steuerberater, Fachberater für Internationales Steuerrecht <strong>Falk Schwella</strong>
</p>
<p>
Telefon: <a href="tel:+49355355480" target="_blank">+49 (0)355-35548-0</a><br>
Telefax: <a href="tel:+49355355419" target="_blank">+49 (0)355-35548-19</a><br>
E-Mail: <a href="mailto:info@steuergestaltung.biz" target="_blank">info[at]steuergestaltung.biz</a><br>
</p>
<p>Amtsgericht Cottbus, Partnerschaftsregister PR 19 CB</p>
<p>Umsatzsteuer-Identifikationsnummer nach § 27a UStG: DE227291560</p>
<p>
Die gesetzliche Berufsbezeichnung Steuerberater/Steuerberaterin wurde in der Bundesrepublik Deutschland (Brandenburg) verliehen.
Die Fachberaterbezeichnung "Fachberater für Internationales Steuerrecht" wurde gemäß 86 Abs. Nr. 11 StBerG als Hinweis auf besondere Kenntnisse bestimmter Steuerechtsgebiete von der
Steuerberaterkammer Brandenburg verliehen.
</p>
<p>Aufsichtsbehörde ist die <a href="https://www.stbk-brandenburg.de" target="_blank">Steuerberaterkammer Brandenburg</a>, Tuchmacherstrasse 48 b, 14482 Potsdam</p>
<hr>
<h5>Berufsrechtliche Regelungen:</h5>
Der Berufsstand der Steuerberater unterliegt im Wesentlichen den nachstehenden berufsrechtlichen Regelungen:
<p>
<br>
   - Steuerberatungsgesetz (StBerG),<br>
   - Verordnung zur Durchführung der Vorschriften über Steuerberater, Steuerbevollmächtigte und Steuerberatungsgesellschaften (DVStB),<br>
   - Berufsordnung der Bundessteuerberaterkammer (BOStB), sowie<br>
   - Steuerberatergebührenverordnung (StBGebV).

<p>Die berufsrechtlichen Regelungen können auf den Internet-Seiten der Bundessteuerberaterkammer <a href="https://www.bstbk.de/de/" target="_blank">www.bstbk.de</a> abgerufen werden.</p>

<p>Die Verleihung der Fachberaterbezeichnung "Fachberater für Internationales Steuerrecht" unterliegt im Wesentlichen der Fachberaterordnung.</p>
<hr>
<h5>Berufshaftpflichtversicherung</h5>
<p>Die Berufshaftpflichtversicherung besteht bei der Versicherungsgemeinschaft für das wirtschaftliche Prüfungs- und Treuhandwesen, Dotzheimer Str. 23, 65185 Wiesbaden.</p>

<p>Der räumliche Geltungsbereich des Versicherungsschutzes umfasst Dienstleistungen zumindest in den Mitgliedsländern der Europäischen Union und genügt
    mindestens den Anforderungen des § 67 Steuerberatungsgesetz (StBerG), 51 ff. Verordnung zur Durchführung der Vorschriften über Steuerberater,
    Steuerbevollmächtigte und Steuerberatungsgesellschaften (DvStB)</p>
<hr>
<h5>Haftungsausschluss</h5>

<h6>1. Inhalt des Onlineangebotes</h6>
<p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen.
Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors
kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte
Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>

<h6>2. Verweise und Links</h6>
<p>Bei direkten oder indirekten Verweisen auf fremde Internetseiten (sogenannte Links), die außerhalb des Verantwortungsbereiches der Autoren liegen,
    haften diese nur dann, wenn sie von den Inhalten Kenntnis haben und es ihnen technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
    Die Autoren erklären hiermit ausdrücklich, dass zum Zeitpunkt der Verlinkung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren.
    Die Diensteanbieter erklären weiterhin, dass sie keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verlinkten Seiten haben.
    Deshalb distanzieren sie sich hiermit ausdrücklich von allen Inhalten aller verlinkten Seiten, die nach der Verlinkung geändert wurden.
    Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen,
    haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>

<p>Sollte ein gesetzter Link nicht funktionieren oder auf der verlinkten Internet-Adresse rechtswidrige Inhalt zu finden sein,
dann bitten wir Sie, uns umgehend per E-Mail zu informieren, damit wir diesen Link erneut überprüfen und gegebenenfalls entfernen können.</p>

<hr>

<h5>Urheberrecht</h5>
<p>
Das Copyright für veröffentlichte, von den Autoren selbst erstellte Grafiken und Text verbleibt ausschliesslich  bei den Autoren dieser Seiten.
Eine Vervielfältigung oder Verwendung solcher Grafiken und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne schriftliche Zustimmung der Autoren nicht gestattet.</p>
      </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'

export default {
    name: 'impressum',
    data() {
        return {

        }
    },
    methods: {
        downloadPDF() {
            const pdf = '/src/assets/Fachberaterurkunde.pdf';
            window.open(pdf);
        }
    }
}
</script>

<style scoped>


#impressum {
    font-size: 800;
    color: #1f6892;
    text-align: left;
    display: block;
    position: static;
    padding-top: 10px;
    padding-bottom: 30px;
}

</style>